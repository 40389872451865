export function dataCenterModel(requestData) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    return {
      dataCenter: getDataCenter(data),
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

function getDataCenter(data) {
  return {
    id: data.id,
    region: data.region,
    priority: data.priority,
  };
}
