export function companyGroupModel(requestData) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    return {
      companyGroup: getCompanyGroup(data),
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

function getCompanyGroup(data) {
  return {
    id: data.id,
    parentGroupID: data.parentGroupID,
    name: data.name,
    userTypeID: data.userTypeID,
    guidID: data.guidID,
    groupCount: data.groupCount,
    userCount: data.userCount,
    isSetCount: data.isSetCount,
  };
}
