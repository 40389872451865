import React from "react";
import "../css/layoutPublicMenuFooter/footerTermsOfUse.scss";
import { Link } from "react-router-dom";

const FooterTermsOfUse = ({ termsOfUseData }) => {
  return (
    <div className="footerTermsOfUse">
      <div className="footerTermsOfUse-container">
        <span>
          <Link to="/page/PrivacyPolicy">{termsOfUseData[0]?.title}</Link>
        </span>{" "}
        |{" "}
        <span>
          <Link to="/page/clarificationText">{termsOfUseData[1]?.title}</Link>
        </span>
      </div>
    </div>
  );
};

export default FooterTermsOfUse;
