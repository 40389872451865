export const getLocalstorage = (value) => {
  return JSON.parse(localStorage.getItem(value));
};

export const setLocalstorage = (value, data) => {
  localStorage.setItem(value, JSON.stringify(data));
};

export const removeLocalstorage = (value) => {
  localStorage.removeItem(value);
};

export const userRemoveAllLocalstorage = () => {
  localStorage.removeItem("ip");
  localStorage.removeItem("xU");
  localStorage.removeItem("uID");
  localStorage.removeItem("userTypeID");
  localStorage.removeItem("viewTypeID");
  localStorage.removeItem("gA");
  localStorage.removeItem("token");
  localStorage.removeItem("hasMultiCompany");
  localStorage.removeItem("company");
  localStorage.removeItem("singleCompany");

  localStorage.removeItem("liveRoomLink");
  localStorage.removeItem("guestUserName");
  localStorage.removeItem("bbbMeetingInfo");

  localStorage.removeItem("isChatShow");
  localStorage.removeItem("selectedGroup");
  localStorage.removeItem("selectedCompany");
  localStorage.removeItem("minchat");
  localStorage.removeItem("chatFrameClass");
  localStorage.removeItem("isChatClose");

  localStorage.removeItem("siteSidebarMenu");
  localStorage.removeItem("dashboardStatistics"); //dashboardStatistics localstorage'dan kaldırıldı fakat geriye dönük kayıtların temizlenmesi için buradan kaldıırlmadı

  localStorage.removeItem("chatUserID");
};
