import {
  initialValuesDate,
  initialValuesTime,
} from "../../utils/dateFormatter/dateRegex";

export function subJourneyModel(requestData) {
  const data = requestData ? requestData.data : false;
  if (data && !requestData.hasFailed) {
    return {
      subJourney: subJourney(data),
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

function subJourney(data) {
  return {
    id: data.id,
    journeyID: data.journeyID,
    journeyCourseName: data.journeyCourseName,
    dataTypeID: data.dataTypeID,
    dataID: data.dataID,
    dataName: data.dataName,
    url: data.url,
    subJourneyGuidID: data.subJourneyGuidID,
    statusID: data.statusID,
    createUser: data.createUser,
    startDate: initialValuesDate(data.startDate),
    startTime: initialValuesTime(data.startTime),
    endDate: initialValuesDate(data.endDate),
    endTime: initialValuesTime(data.endTime),
    imagePath: data.dataImagePath,
    cardTemplateColor: data.cardTemplateColor,
    isLaterShowAnswers: data.isLaterShowAnswers,
    isLaterShowAnswersDate: initialValuesDate(
      data.isLaterShowAnswersDate.split(" ")[0]
    ),
    isLaterShowAnswersTime: initialValuesTime(
      data.isLaterShowAnswersDate.split(" ")[1]
    ),
    dateFormatID: 0,
  };
}
