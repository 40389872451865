import React, { memo } from "react";
import { Link } from "react-router-dom";
import Icon from "../../../partnerComponents/icons/icon";
import "../../../../Assets/styles/fileSass/custom.scss";
import GetWord from "../../../utils/language/getWord";
import { findReturnUrl, siteType } from "../../../utils/appSettings";
import { isMobile } from "react-device-detect";
import "../css/layoutPublicHeader/layoutPublicHeader.scss";

const LayoutPublicHeader = memo(() => {
  const { isLoggedIn } = global;

  return (
    <div className="containerCenter layoutPublic-header">
      <div className="layoutPublic-header-logo">
        <Link to="/" className="layoutPublic-header-logo-home">
          <Icon name="icon-a-UcanAcademyLogo" />
        </Link>

        {siteType() === "Localhost" && (
          <div className="layoutPublic-header-logo-name">test</div>
        )}

        {(siteType() === "Test" || siteType() === "AcademyTest") && (
          <div className="layoutPublic-header-logo-name">test</div>
        )}

        {siteType() === "Dev" && (
          <div className="layoutPublic-header-logo-name">dev</div>
        )}
      </div>

      <Link to="/" className="layoutPublic-header-logo-home-mobil">
        <Icon name="icon-a-UcanAcademyLogo" />
      </Link>

      <div className="layoutPublic-header-center">
        <Link to="/page/welcome">{GetWord("HomePage")} </Link>
        <Link to="/page/about">{GetWord("About")}</Link>
        <Link to="/page/contact">{GetWord("Contact")}</Link>
      </div>

      <div className="layoutPublic-header-right">
        <Link
          to="/page/demoRequest"
          className="defaultButton defaultButton-color2c51fe defaultButton-borderRadius25 defaultButton-border2c51fe"
        >
          {GetWord("RequestDemo")}
        </Link>
        {isLoggedIn ? (
          <Link
            to="/educations"
            className="defaultButton defaultButton-colorfff defaultButton-bg2c51fe defaultButton-borderRadius25"
          >
            {GetWord("Educations")}
          </Link>
        ) : (
          <>
            <Link
              to={"/login" + findReturnUrl()}
              className="defaultButton defaultButton-colorfff defaultButton-bg2c51fe defaultButton-borderRadius25"
            >
              {GetWord("Login")}
            </Link>
            {!isMobile ? (
              <Link
                to="/login/boardLogin"
                className="defaultButton defaultButton-color2c51fe defaultButton-borderRadius25 defaultButton-border2c51fe"
              >
                {GetWord("BoardLogin")}
              </Link>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
});

export default LayoutPublicHeader;
