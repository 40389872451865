import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Service from "../../Service";

export const sendRequestTranslateToBackend = createAsyncThunk(
  "backend/sendRequest",
  async () => {
    const service = new Service();
    const translationAccesses = global.translationAccesses;

    if (translationAccesses && translationAccesses.length > 0) {
      var pageName = global.pageName;

      const requestData = {
        data: {
          pageName: pageName,
          keys: translationAccesses,
        },
      };

      service.configuration
        .increaseTranslationAccesses(requestData)
        .then((res) => {
          if (res?.data && !res.hasFailed) {
            global.translationAccesses = [];
          }
        });

      global.pageName = "";
    }
  }
);

const translationPostSlice = createSlice({
  name: "translationPost",
  initialState: {
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendRequestTranslateToBackend.pending, (state) => {
      state.error = null;
    });
    //     .addCase(sendRequestTranslateToBackend.fulfilled, (state, action) => {
    //       state.data = action.payload;
    //     })
    //     .addCase(sendRequestTranslateToBackend.rejected, (state, action) => {
    //       state.error = action.error.message;
    //     });
  },
});

export default translationPostSlice.reducer;
