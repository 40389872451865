// segmented = true  // parçalayacak geri döndürür saat ve dk
export function clockRegex(params, segmented) {
  if (!params) {
    return "-";
  } else {
    const date = new Date(params);
    const hour = date.getHours();
    const minutes = date.getMinutes();

    if (segmented) {
      return {
        hour,
        minutes,
      };
    }
    return (
      (String(hour).length === 1 ? "0" + hour : hour) +
      ":" +
      (String(minutes).length === 1 ? "0" + minutes : minutes)
    );
  }
}
