import GetWord from "../../utils/language/getWord";
import { imagesReplace } from "../../utils/mixedControl";

export const homeModel = (data) => {
  const {
    announcement,
    courses,
    analytics1,
    analytics2,
    analytics5LastLessons,
    analytics3EducationActivityData,
    analytics3EducationActivityStartDate,

    analytics4TestData,
    analytics4TestStartDate,
    analytics4TestMaxResult,
    showAnalytics,
  } = data;

  return {
    announcement,
    courses: coursesList(courses),
    lastLessons: analytics5LastLessons,
    analytics1: analytics1List(analytics1),
    analytics2: analytics2List(analytics2),
    trainingPerformance: analytics3EducationActivityData,
    analytics3EducationActivityStartDate,

    myTests: analytics4TestData,
    analytics4TestStartDate,
    analytics4TestMaxResult,
    showAnalytics,
  };
};

export const analytics1List = (data) => {
  if (data) {
    const { completed, left, percentage, total } = data;
    return {
      percent: percentage,
      list: [
        {
          label: GetWord("TotalLessons"),
          value: total,
        },
        {
          label: GetWord("CompletedCourse"),
          value: completed,
        },
        {
          label: GetWord("LessonLeft"),
          value: left,
        },
        {
          label: GetWord("Development"),
          value: percentage,
        },
      ],
    };
  }
  return null;
};

export const analytics2List = (data) => {
  if (data) {
    const {
      completed,
      enterPercentage,
      notCompleted,
      successPercentage,
      total,
    } = data;
    return {
      percent: enterPercentage,
      list: [
        {
          label: GetWord("Total"),
          value: total,
        },
        {
          label: GetWord("Completed"),
          value: completed,
        },
        {
          label: GetWord("Remaining"),
          value: notCompleted,
        },
        {
          label: GetWord("GradeAverage"),
          value: successPercentage,
        },
      ],
    };
  }
  return null;
};

export const coursesList = (items) => {
  let newData = [];
  if (items?.length > 0) {
    newData = items.map((item) => {
      const { imagePath } = item;
      return {
        ...item,
        imagePath: imagesReplace(imagePath, "size1"),
      };
    });
  }
  return newData;
};
