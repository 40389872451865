import baseService from "./baseService";

export default class Account extends baseService {
  constructor() {
    super();
    this.schemaName = "React/Account";
  }

  getUserCompanyCount(entity) {
    return this.postObject(this.schemaName, "GetUserCompanyCount", entity);
  }

  googleLogin(entity) {
    return this.postObject(this.schemaName, "google", entity);
  }

  facebookLogin(entity) {
    return this.postObject(this.schemaName, "facebook", entity);
  }

  microsoftLogin(entity) {
    return this.postObject(this.schemaName, "microsoft", entity);
  }
}
