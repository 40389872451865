import moment from "moment";
import { GetI18n } from "../../utils/mixedControl";

export const layoutPrivateMenuModel = (items) => {
  return items.map((item) => {
    const {
      id,
      name,
      backgroundColor,
      url,
      icon,
      sortOrder,
      parentList,
      menuSubItems,
      code,
    } = item;
    return {
      id,
      name: GetI18n(item, "name"),
      backgroundColor,
      url,
      icon,
      sortOrder,
      menuSubItems,
      parentList,
      code,
    };
  });
};
