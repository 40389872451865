import Search from "./search";
import ConfigurationService from "./configuration";
import Content from "./content";
import Education from "./education";
import Organization from "./organization";
import Visa from "./visa";
import React from "./react";
import Sales from "./sales";
import CommCo from "./commco";
import Logging from "./logging";
import Account from "./account";
import Analytics from "./analytics";
import RouterManagerService from "./routerManager";
import ChatService from "./chat/ChatService";
import ProductSales from "./productSalesService";

export default class Service {
  configuration = new ConfigurationService();
  visa = new Visa();
  content = new Content();
  education = new Education();
  organization = new Organization();
  sales = new Sales();
  search = new Search();
  react = new React();
  commCo = new CommCo();
  logging = new Logging();
  account = new Account();
  analytics = new Analytics();
  routerManagerService = new RouterManagerService();
  chatService = new ChatService();
  productSales = new ProductSales();
}
