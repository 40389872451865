import React, { memo } from "react";
import PropTypes from "prop-types";
import LoadingGif from "../../../Assets/images/icons/loading.gif";
//<LoadingCorporate innerPageActive={true}/>

// innerPageActive = sayfa içerisinde arka plana pusluk yapıyor

const LoadingCorporate = memo((props) => {
  const { innerPageActive, innerPageActiveFixed, style } = props;
  return (
    <div
      className={
        "loading " +
        (innerPageActive ? "loading--innerPage" : "") +
        (innerPageActiveFixed
          ? "loading--innerPage loading--innerPage-fixed"
          : "")
      }
      style={style}
    >
      <img src={LoadingGif} alt="loading" />
    </div>
  );
});

LoadingCorporate.propTypes = {
  innerPageActive: PropTypes.bool,
};

export default LoadingCorporate;
