import React from "react";
import { Decrypt } from "../encrypted";

var currentDomainName = "ucan.academy";

export function hubConnectionUrl() {
  if (siteType() === "Localhost") return "http://localhost:59600/hubs/login";
  else if (siteType() === "Test" || siteType() === "AcademyTest")
    return "https://testapi." + currentDomainName + "/hubs/login";
  else if (siteType() === "Live") {
    return "https://loginapi." + currentDomainName + "/hubs/login";
  } else if (siteType() === "Dev") {
    return "https://devapi." + currentDomainName + "/hubs/login";
  }
}

export function chatHubConnectionUrl() {
  if (siteType() === "Localhost") return "http://localhost:59601/hubs/Chat";
  else if (siteType() === "Test" || siteType() === "AcademyTest")
    return "https://chattestapiv2." + currentDomainName + "/hubs/Chat";
  else if (siteType() === "Live") {
    return "https://chatapi." + currentDomainName + "/hubs/Chat";
  } else if (siteType() === "Dev") {
    return "https://devchatapi." + currentDomainName + "/hubs/Chat";
  }
}

export function siteUrl() {
  if (siteType() === "Localhost") return "http://localhost:3000";
  else if (siteType() === "Test" || siteType() === "AcademyTest")
    return "https://testapi." + currentDomainName;
  else if (siteType() === "Live") {
    return "https://loginapi." + currentDomainName;
  } else if (siteType() === "Dev") {
    return "https://devapi." + currentDomainName;
  }
}

export function siteType() {
  return global.siteType;
}

export const isGlobalAdmin = () => {
  var result = 0;

  if (localStorage.getItem("xU") && localStorage.getItem("gA")) {
    var userName = Decrypt(JSON.parse(localStorage.getItem("xU")));
    var globalAdmin = Decrypt(JSON.parse(localStorage.getItem("gA")));
    globalAdmin = globalAdmin.replace("*" + userName, "");
    result = globalAdmin;
  }

  return result;
};

export const testType = (id) => {
  if (id === 0) return "Text";
  if (id === 1) return "Picture";
  if (id === 2) return "Pdf";
};

export const userImageOrText = (
  imagePath,
  name,
  firstName,
  lastName,
  value
) => {
  return imagePath ? (
    <img
      src={imagePath}
      alt={name}
      onError={(e) => {
        e.target.style.display = "none";
      }}
    />
  ) : (
    <div
      className="userText"
      // style={{
      //   backgroundColor: colorList[colorIndex],
      // }}
    >
      <span>
        {value ? (
          <React.Fragment>{value + (value > 100 ? "+" : "")}</React.Fragment>
        ) : (
          <React.Fragment>
            {firstName
              ? firstName[0].toLocaleUpperCase()
              : name && name.split(" ").length > 0
              ? name.split(" ")[0][0].toLocaleUpperCase()
              : null}
            {lastName
              ? lastName[0].toLocaleUpperCase()
              : name && name.split(" ").length > 1
              ? name.split(" ")[1][0].toLocaleUpperCase()
              : null}
          </React.Fragment>
        )}
      </span>
    </div>
  );
};

export const findReturnUrl = () => {
  var result = "";

  var returnUrl = new URLSearchParams(window.location.search).get("returnUrl");
  if (
    returnUrl &&
    returnUrl !== null &&
    returnUrl !== "null" &&
    returnUrl !== "" &&
    returnUrl !== undefined
  )
    result = "?returnUrl=" + returnUrl;

  return result;
};
