import { createSlice } from "@reduxjs/toolkit";
import Service from "../../../Service";
import { layoutPrivateMenuModel } from "../../../Shared/models";
import { Decrypt } from "../../../Shared/utils/encrypted";
import { getLocalstorage } from "../../../Shared/utils/localStore/localStorage";
import { initialListState } from "../../initialState";
import { languageIDGetNew } from "../../../Shared/utils/language/languageIDGet";

const layoutPrivateMobileMenuSlice = createSlice({
  name: "siteMobileMenu",
  initialState: initialListState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.items = payload;
    },
  },
});

export const mobileMenu = (state) => state.siteMobileMenu;
export const { setItemList } = layoutPrivateMobileMenuSlice.actions;
export default layoutPrivateMobileMenuSlice.reducer;

export function getSiteMobileMenu() {
  return async (dispatch) => {
    const service = new Service();
    const requestData = {
      languageID: languageIDGetNew(),
      data: {
        code: "MobileMenu",
        userTypeID: getLocalstorage("userTypeID")
          ? Decrypt(getLocalstorage("userTypeID"))
          : 0,
      },
    };
    service.content.getMenu(requestData).then((res) => {
      if (res?.data && !res.hasFailed) {
        const newData = layoutPrivateMenuModel(res.data.menuDetails);
        dispatch(setItemList(newData));
      }
    });
  };
}
