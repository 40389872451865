import { createSlice } from "@reduxjs/toolkit";
import Service from "../../../Service";
import { initialObjectState } from "../../initialState";

const courseViewTypeSlice = createSlice({
  name: "courseViewType",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.viewType = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
});

export const courseViewTypeSelector = (state) => state.courseViewType; // initialState üzerindeki bilgileri dışarı aktarma
export const { setItemList, failed } = courseViewTypeSlice.actions; // functions dışarıya aktarılması
export default courseViewTypeSlice.reducer;

export function getCourseViewType() {
  return async (dispatch) => {
    const service = new Service();
    const requestData = { data: {} };
    service.react.getCourseViewType(requestData).then((res) => {
      if (res?.data && !res.hasFailed) {
        dispatch(setItemList(res.data));
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    });
  };
}
