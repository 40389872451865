export function addMultiUserModel(requestData) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    const { errorMessages, statusID, importLogs } = data;
    return {
      item: {
        errorMessages,
        statusID,
        importLogs,
      },
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}
