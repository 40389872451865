export function courseLessonModel(requestData) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    return {
      courseLesson: getCourseLesson(data),
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

function getCourseLesson(data) {
  return {
    lessonID: data.lessonID,
    courseID: data.courseID,
    name: data.name,
    description: data.description,
    statusID: data.statusID,
    sortOrder: data.sortOrder,
    tags: data.tags,
    oldSelectedTagList: data.oldSelectedTagList,
    selectedImageSetID: data.selectedImageSetID,
    isEvulationExam: data.isEvulationExam,
    examID: data.examID,
  };
}
