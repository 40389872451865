import { lazy } from "react";

// user giriş
const RouterMembership = lazy(() => import("./routerMembership"));
const Login = lazy(() => import("../Components/membership/login"));
const ForgotPassword = lazy(() =>
  import("../Components/membership/forgotPassword")
);
const ChangePassword = lazy(() =>
  import("../Components/membership/changePassword")
);

//private
const RouterPrivate = lazy(() => import("./routerPrivate"));
const Home = lazy(() => import("../Components/private/home"));
const Educations = lazy(() => import("../Components/private/educations"));
const Courses = lazy(() => import("../Components/private/courses"));
const LesoonDetail = lazy(() => import("../Components/private/lesoonDetail"));
const LessonDetailV2 = lazy(() =>
  import("../Components/private/lessonDetailV2")
);
const Exams = lazy(() => import("../Components/private/exams"));
const LiveRoom = lazy(() => import("../Components/private/liveRoom"));
const MyProfile = lazy(() => import("../Components/private/myProfile"));
const Recorded = lazy(() => import("../Components/private/recorded"));
const ShowLiveRoom = lazy(() => import("../Components/private/showLiveRoom"));

const DenemeEkrani = lazy(() => import("../Components/denemeEkrani"));

//public
const RouterPublic = lazy(() => import("./routerPublic"));
const About = lazy(() => import("../Components/public/about"));
const Welcome = lazy(() => import("../Components/public/welcome"));
const RequestDemo = lazy(() => import("../Components/public/requestDemo"));
const Contact = lazy(() => import("../Components/public/contact"));

export {
  // membership
  RouterMembership,
  Login,
  ForgotPassword,
  ChangePassword,
  //private
  RouterPrivate,
  Home,
  Educations,
  Courses,
  LesoonDetail,
  LessonDetailV2,
  Exams,
  LiveRoom,
  MyProfile,
  Recorded,
  ShowLiveRoom,
  //
  DenemeEkrani,
  RouterPublic,
  About,
  Welcome,
  RequestDemo,
  Contact,
};
