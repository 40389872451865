import BgMask from "../../../Assets/images/background/sliderBg.svg";
import PublicBreadCrumb from "../publicBreadCrumbs";
import "./css/publicPageHeader.scss";

const PublicPageHeader = ({ item , className }) => {
  const { name, imagePath } = item || {};
  let componentClass = "publicPageHeader-header";
  if(className){
    componentClass += ` ${className}`;
  }
  return (
    <div className={componentClass}>
      <PublicBreadCrumb pageName={name} title = {name} />
      <img src={imagePath} alt={name} className="publicPageHeader-header-img"></img>

      <div className="publicPageHeader-header-mask">
        <img src={BgMask} alt="publicPageHeaderMask" />
      </div>
    </div>
  );
};

export default PublicPageHeader;
