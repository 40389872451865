import { createSlice } from "@reduxjs/toolkit";
import Service from "../../../Service";
import { initialObjectState } from "../../initialState";

const avatarSlice = createSlice({
  name: "avatar",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
});

export const avatarData = (state) => state.avatar; // initialState üzerindeki bilgileri dışarı aktarma

export const { setItemList, failed } = avatarSlice.actions; // functions dışarıya aktarılması

export default avatarSlice.reducer;

export function getUserAvatar() {
  return async (dispatch) => {
    const service = new Service();
    const requesData = { data: {} };
    service.visa.getUserAvatarData(requesData).then((res) => {
      if (res?.data && !res.hasFailed) {
        dispatch(setItemList(res.data));
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    });
  };
}
