import { createSlice } from "@reduxjs/toolkit";
import Service from "../../Service";
import { pageModel } from "../../Shared/models";
import { initialObjectState } from "../initialState";

const aboutSlice = createSlice({
  name: "about",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
});

export const aboutSelector = (state) => state.about; // initialState üzerindeki bilgileri dışarı aktarma
export const { setItemList, failed } = aboutSlice.actions; // functions dışarıya aktarılması
export default aboutSlice.reducer;

export function getAboutPage() {
  return async (dispatch) => {
    const service = new Service();
    const requestData = { data: { code: "about" } };
    service.react.getPage(requestData).then((res) => {
      if (res?.data && !res.hasFailed) {
        const newData = pageModel(res.data);
        dispatch(setItemList(newData));
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    });
  };
}
