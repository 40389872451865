export function courseModel(requestData) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    return {
      course: getCourse(data),
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

function getCourse(data) {
  return {
    id: data.id,
    courseTypeID: data.courseTypeID,
    name: data.name,
    description: data.description,
    sortOrder: data.sortOrder,
    parentCourseID: data.parentCourseID,
    statusID: data.statusID,
    tags: data.tags,
    oldSelectedTagList: data.oldSelectedTagList,
    changeCourseID: data.changeCourseID, // Bir kurstan değiştirilmiş bir kurs olduğu buradan yakalanır. Değiştirilmiş yeni kursun ID'si.
    changeCourseGuidID: data.changeCourseGuidID,
    selectedImageSetID: data.selectedImageSetID,
    applyImageToChild: data.applyImageToChild,
  };
}
