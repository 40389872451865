import baseService from "./baseService";

export default class RouterManagerService extends baseService {
  constructor() {
    super();
    this.schemaName = "RouterManagerService";
    this.schemaNameReact = "React/routermanager";
  }

  GetRouterPageList(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetRouterPageList",
      entity,
      cancelToken
    );
  }

  GetRouterPage(entity) {
    return this.postObject(this.schemaNameReact, "GetRouterPage", entity);
  }

  UpdateRouterPage(entity) {
    return this.postObject(this.schemaName, "UpdateRouterPage", entity);
  }
}
