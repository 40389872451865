import { createSlice } from "@reduxjs/toolkit";
import Service from "../../../Service";
import { imagesReplace } from "../../../Shared/utils/mixedControl";
import { initialObjectState } from "../../initialState";

const myProfileSlice = createSlice({
  name: "myProfile",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
    loading: (state, { payload }) => {
      state.loading = true;
    },
  },
});

export const myProfileData = (state) => state.myProfile; // initialState üzerindeki bilgileri dışarı aktarma

export const { setItemList, failed, loading } = myProfileSlice.actions; // functions dışarıya aktarılması

export default myProfileSlice.reducer;

export function getMyProfile() {
  return async (dispatch) => {
    dispatch(loading());
    const service = new Service();
    const requesData = { data: {} };
    service.visa.getMyProfile(requesData).then((res) => {
      if (res?.data && !res.hasFailed) {
        dispatch(
          setItemList({
            ...res.data,
            imagePath: imagesReplace(res.data.imagePath),
          })
        );
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    });
  };
}
