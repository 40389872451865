import { getLocalstorage } from "../localStore/localStorage";
import { findData } from "../mixedControl";

// localstorge deki  dili alıyor ve dil id dönüyor
export function languageIDGet() {
  const languageName = getLocalstorage("language") ? getLocalstorage("language").toLocaleLowerCase() : "tr";

  const languages = global.config?.languages;

  if (languages && languageName) {
    const newFindData = findData(languages, "isoCode", languageName);

    const isoData = newFindData || findData(languages, "isoCode", "en");

    return isoData ? isoData.id : 1;
  } else {
    return 1;
  }
}

export function languageIDGetNew() {
  const languageName = getLocalstorage("language") ? getLocalstorage("language").toLocaleLowerCase() : "tr";

  const languages = global.config?.languages;

  if (languages && languageName) {
    const newFindData = findData(languages, "code", languageName);

    const isoData = newFindData || findData(languages, "code", "en");

    return isoData ? isoData.id : 1;
  } else {
    return 1;
  }
}

// // local deki dile göre global üzerindeki dil datasın da yakalıyor ve dönüyor
// export function languageIsoCodeData() {
//   const languageName = getLocalstorage("language").toLocaleLowerCase();
//    const languages = global.config?.languages;

//   if ( languages) {
//     const languageNameControl = findIsoCode(languages, languageName);
//     return languageNameControl ? languageNameControl : {};
//   } else {
//     return {};
//   }
// }
