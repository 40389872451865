import { createSlice } from "@reduxjs/toolkit";
import Service from "../../Service";
import { pageModel } from "../../Shared/models";
import { initialObjectState } from "../initialState";

const contactSlice = createSlice({
  name: "contact",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
});

export const contactSelector = (state) => state.contact; // initialState üzerindeki bilgileri dışarı aktarma

export const { setItemList, failed } = contactSlice.actions; // functions dışarıya aktarılması

export default contactSlice.reducer;

export function getContactPage(code) {
  return async (dispatch) => {
    const service = new Service();
    const requestData = { data: { code: "contact" } };
    service.react.getPage(requestData).then((res) => {
      if (res?.data && !res.hasFailed) {
        const newData = pageModel(res.data);
        dispatch(setItemList(newData));
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    });
  };
}
