export function addNewCourseModel(requestData) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    const { courseTypes, courseImageSets, templateID, templateTypeID } = data;
    return {
      item: {
        courseTypes,
        courseImageSets,
        templateID,
        templateTypeID,
      },
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}
