import { siteType } from "../appSettings";

function GetWord(key) {
  const translations = global.translation; //translation json
  const newKey = "Site." + key;

  if (translations && key?.indexOf(" ") === -1) {
    const newKeyLowerCase = newKey.toLocaleLowerCase();

    // canlı ortam hariç kelimelerin kaydedilmesi
    if (siteType() !== "Live") {
      if (global.pageName === "" || global.pageName === undefined) {
        global.pageName = window.location.pathname;
      }

      if (global.translationAccesses && global.translationAccesses.length > 0) {
        if (
          global.translationAccesses.filter((op) => op.name === newKey).length <
          1
        ) {
          global.translationAccesses = [
            ...global.translationAccesses,
            { name: newKey },
          ];
        }
      } else {
        global.translationAccesses = [{ name: newKey }];
      }
    }

    var selectTranslations = translations[newKeyLowerCase];
    if (selectTranslations) {
      return selectTranslations;
    } else {
      // kelime küçültüldüğünde "I" => "ı" olarak çevriliyor bu durum bulunamamasına neden oluyor.
      // böyle bir durum için küçük "ı" => "i" ye çevrilip tekrar aranıyor.
      selectTranslations = translations[newKeyLowerCase.replaceAll("ı", "i")];

      if (selectTranslations) {
        return selectTranslations;
      } else if (selectTranslations === "") {
        //boş gözükenleri direkt kendini döndürüyor
        return newKey;
      }
    }

    return newKey;
  } else {
    return newKey;
  }
}

export default GetWord;
