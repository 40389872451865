import { createSlice } from "@reduxjs/toolkit";
import Service from "../../../Service";
import { homeModel } from "../../../Shared/models";
import { initialObjectState } from "../../initialState";

const homeSlice = createSlice({
  name: "home",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
});

export const homeData = (state) => state.home; // initialState üzerindeki bilgileri dışarı aktarma

export const { setItemList, failed } = homeSlice.actions; // functions dışarıya aktarılması

export default homeSlice.reducer;

export function getDashboard() {
  return async (dispatch) => {
    const service = new Service();
    const requestData = {};
    service.react.getDashboard(requestData).then((res) => {
      if (res?.data && !res.hasFailed) {
        const newData = homeModel(res.data);
        dispatch(setItemList(newData));
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    });
  };
}
