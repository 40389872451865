import ChatBaseServices from "./ChatBaseServices";

export default class ChatService extends ChatBaseServices {
  constructor() {
    super();
    this.organizationSchemaName = "organization";
    this.chatSchemaName = "chat";
    this.transferSchemaName = "transfer";
  }

  GetCompanies(entity) {
    return this.postObject(this.organizationSchemaName, "GetCompanies", entity);
  }
  GetCompanyGroups(entity) {
    return this.postObject(
      this.organizationSchemaName,
      "GetCompanyGroups",
      entity
    );
  }

  GetCompanyGroupInfo(entity) {
    return this.postObject(
      this.organizationSchemaName,
      "GetCompanyGroupInfo",
      entity
    );
  }

  GetMessages(entity) {
    return this.postObject(this.chatSchemaName, "GetMessages", entity);
  }

  AddMessage(entity) {
    return this.postObject(this.chatSchemaName, "AddMessage", entity);
  }

  DeleteMessage(entity) {
    return this.postObject(this.chatSchemaName, "DeleteMessage", entity);
  }

  PostDelete(id, url) {
    let entity = {
      data: {
        MessageGuidID: id,
      },
    };
    return this.postToUrl(url, entity);
  }

  GetCompanyGroupsNotTransfered(entity) {
    return this.postObject(
      this.transferSchemaName,
      "GetCompanyGroupsNotTransfered",
      entity
    );
  }

  GetCompanyGroupUsersNotTransfered(entity) {
    return this.postObject(
      this.transferSchemaName,
      "GetCompanyGroupUsersNotTransfered",
      entity
    );
  }

  GetUsersNotTransfered(entity) {
    return this.postObject(
      this.transferSchemaName,
      "GetUsersNotTransfered",
      entity
    );
  }

  updateUserCurrentCompanyGroup(entity) {
    return this.postObject(
      this.organizationSchemaName,
      "updateUserCurrentCompanyGroup",
      entity
    );
  }

  getUserNotTransfered(entity) {
    return this.postObject(
      this.transferSchemaName,
      "GetUserNotTransfered",
      entity
    );
  }
}
