import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getLocalstorage,
  setLocalstorage,
} from "../../Shared/utils/localStore/localStorage";
import { findData } from "../../Shared/utils/mixedControl";
import { initialObjectState } from "../initialState";

const translationResourceSlice = createSlice({
  name: "translation",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.translations = payload;
    },
  },
});

export const translationData = (state) => state.translation; // initialState üzerindeki bilgileri dışarı aktarma
export const { setItemList } = translationResourceSlice.actions; // functions dışarıya aktarılması
export default translationResourceSlice.reducer;

export function getTranslationResource() {
  return async (dispatch) => {
    const languages = global.config?.languages;
    const language = getLocalstorage("language"); // eğer localde dil var ise
    var reactBundlePath = findData(
      languages,
      "code",
      language
    )?.reactBundlePath;

    //localdeki translation data
    const {
      translations_translation,
      translations_language,
      translations_reactBundlePath,
    } = getLocalstorage("translation") || {};

    //duruma göre verileri localden alıp setliyor
    if (
      translations_translation &&
      translations_language === language &&
      translations_reactBundlePath === reactBundlePath
    ) {
      global.translation = translations_translation;
      dispatch(setItemList(translations_translation));
      return;
    }

    //localde dil yok ise
    await axios({
      method: "GET",
      url: reactBundlePath,
      data: {},
    })
      .then((response) => {
        const translationData = response?.data;

        if (translationData) {
          let newData = {};
          for (const key in translationData) {
            newData = {
              ...newData,
              [key.toLocaleLowerCase()]: translationData[key],
            };
          }

          //locel atıyor
          setLocalstorage("translation", {
            translations_translation: newData,
            translations_language: language,
            translations_reactBundlePath: reactBundlePath,
          });

          global.translation = newData;

          dispatch(setItemList(newData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
