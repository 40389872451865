import { getLocalstorage, removeLocalstorage, setLocalstorage } from "../localStore/localStorage";

// ilk girildiğinde veya bir dil yollandığında locale yazıyor
export default function languageLocalStorageControl(languageName) {
  const languageNameLocalStorage = getLocalstorage("language");

  if (languageNameLocalStorage && languageName) {
    removeLocalstorage("siteSidebarMenu")
    setLocalstorage("language", languageName);
    window.location.reload();
  } else {
    // eğer dışardan bir dil gelmiyorsa browser diline bakıyor.
    if (!languageNameLocalStorage) {
      const navigatorLanguageName = navigatorLanguage();
      setLocalstorage("language", navigatorLanguageName);
    } else {
      return null;
    }
  }
}

//browser dilini alıyor
export function navigatorLanguage() {
  const browserLanguage = navigator.language;

  if (browserLanguage) {
    const newbrowserLanguage = (
      browserLanguage[0] + browserLanguage[1]
    ).toLocaleLowerCase();
    if (newbrowserLanguage) {
      return newbrowserLanguage;
    } else {
      return "en";
    }
  } else {
    return "en";
  }
}
