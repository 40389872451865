import baseService from "./baseService";

export default class ProductSales extends baseService {
  constructor() {
    super();
    this.schemaName = "React/ProductSales";
  }

  getProducts(entity) {
    return this.postObject(this.schemaName, "GetProducts", entity);
  }

  getProduct(entity) {
    return this.postObject(this.schemaName, "GetProduct", entity);
  }

  updateProduct(entity) {
    return this.postObject(this.schemaName, "UpdateProduct", entity);
  }

  GetProductSales(entity) {
    return this.postObject(this.schemaName, "GetProductSales", entity);
  }

  GetProductSalesQRCodes(entity) {
    return this.postObject(this.schemaName, "GetProductSalesQRCodes", entity);
  }

  getProductSaleQrCodesForDownload(entity) {
    return this.postObject(
      this.schemaName,
      "GetProductSaleQrCodesForDownload",
      entity
    );
  }

  UpdateProductSale(entity) {
    return this.postObject(this.schemaName, "UpdateProductSale", entity);
  }

  checkGiftCardGuidID(entity) {
    return this.postObject(this.schemaName, "CheckGiftCardGuidID", entity);
  }

  UpdateProductSaleQRCodeGenerator(entity) {
    return this.postObject(
      this.schemaName,
      "UpdateProductSaleQRCodeGenerator",
      entity
    );
  }

  CheckProductSalesCount() {
    return this.getObject(this.schemaName, "CheckProductSalesCount");
  }

  CheckProductSaleCount(entity) {
    return this.postObject(this.schemaName, "CheckProductSaleCount", entity);
  }

  UserSaveGiftCardGuidID(entity) {
    return this.postObject(this.schemaName, "UserSaveGiftCardGuidID", entity);
  }
}
