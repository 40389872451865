import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingCorporate from "../../../Shared/partnerComponents/loading/loadingCorporate";
import PublicPageHeader from "../../../Shared/partnerComponents/publicPageHeader";
import {
  clarificationTextSelector,
  getclarificationTextPage,
} from "../../../Store/public/clarificationTextSlice";
import "./css/clarificationText.scss";
const ClarificationText = () => {
  const { item, hasErrors } = useSelector(clarificationTextSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!item) dispatch(getclarificationTextPage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const componentControl = useMemo(() => {
    if (!hasErrors && item) {
      const { widgets, headline } = item;

      return (
        <>
          {headline && <PublicPageHeader item={headline} />}
          {widgets && (
            <div className="clarificationText-inner">
              <div
                className="clarificationText-inner__title"
                dangerouslySetInnerHTML={{ __html: widgets[0].name }}
              />
              <div
                className="clarificationText-inner__description"
                dangerouslySetInnerHTML={{ __html: widgets[0].description }}
              />
            </div>
          )}
        </>
      );
    } else {
      return <LoadingCorporate />;
    }
  }, [item, hasErrors]);

  return <div className="clarificationTextPage">{componentControl}</div>;
};

export default ClarificationText;
