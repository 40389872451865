import moment from "moment";
import { weekdays } from "../datas/weekdays";
import { monthsGetword } from "../datas/month";
import GetWord from "../language/getWord";

// date ekrana yazılacak formata çevirir
export function dateRegex(params, segmented) {
  if (!params) {
    return "-";
  } else {
    const date = new Date(params);
    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.getMonth();
    const newMonth = month + 1;
    const monthControl =
      String(newMonth).length === 1 ? "0" + newMonth : newMonth;
    const dayControl = String(day).length === 1 ? "0" + day : day;

    if (segmented) {
      const dayString = weekdays[date.getDay()];
      const monthString = monthsGetword[month];
      return {
        day,
        month: newMonth,
        year,
        monthString,
        dayString,
        fullStringDate:
          day + " " + GetWord(monthString) + " " + GetWord(dayString),
      };
    }

    return dayControl + "." + monthControl + "." + year;
  }
}

// tarih sadece yollanırsa tarih formatlayıp yollar saat le yollarsak saatle yollar
export function dateRequestFormat(date, clock) {
  const year = date.getFullYear();
  const day = date.getDate();
  const month = date.getMonth();

  if (clock) {
    const hour = Number(clock[0] + clock[1]);
    const minutes = Number(clock[3] + clock[4]);
    const utc = Date.UTC(year, month, day, hour, minutes, "00");

    return new Date(utc).toISOString();
  } else {
    return new Date(Date.UTC(year, month, day)).toISOString();
  }
}

// initialValues için bugünün tarihini oluşturuyor
export function initialValuesMoment(date) {
  return moment(date ? new Date(date) : new Date(), "DD/MM/YYYY");
}

export function initialValuesDate(date) {
  if (date) {
    const [day, month, year] = date.split("/");
    const newDate = new Date(year, month - 1, day);
    return initialValuesMoment(newDate);
  }
  return null;
}

// initialValues içerisindeki timepicker için varsayılan saat oluşturur
export function initialValuesTime(time) {
  const splitTime = time.split(":");
  if (splitTime) {
    const date = new Date();
    date.setHours(splitTime[0]);
    date.setMinutes(splitTime[1]);
    return initialValuesMoment(date);
  }
  return null;
}

export function getTimeFromDate(dateStr) {
  return new Date(dateStr).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function getDateForDatePicker(
  defaultDay = 0,
  defaultMonth = 0,
  defaultYear = 0
) {
  const date = new Date();
  let day = defaultDay === 0 ? date.getDate().toString() : defaultDay;
  day = day.length === 1 ? 0 + day : day;
  let month =
    defaultMonth === 0 ? (date.getMonth() + 1).toString() : defaultMonth;
  month = month.length === 1 ? 0 + month : month;
  let year = defaultYear === 0 ? date.getFullYear() : defaultYear;
  return month + "/" + day + "/" + year;
}

export function createDateForSave(date) {
  const resultdate = new Date(date);
  let day = resultdate.getDate().toString();
  day = day.length === 1 ? 0 + day : day;
  let month = (resultdate.getMonth() + 1).toString();
  month = month.length === 1 ? 0 + month : month;
  let year = resultdate.getFullYear();
  return day + "-" + month + "-" + year;
}

export function createTimeForSave(time) {
  const resultTime = new Date(time);
  let startHour = resultTime.getHours().toString();
  startHour = startHour.length === 1 ? 0 + startHour : startHour;
  let startMinute = resultTime.getMinutes().toString();
  startMinute = startMinute.length === 1 ? 0 + startMinute : startMinute;
  return (startHour + ":" + startMinute).toString();
}

export function getCurrentTime() {
  const date = new Date();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return hour + ":" + minute + ":" + second;
}

// bugunun tarihi ile key oluşturma
// fristText  başına kelime eklemek istiyorsak

// export function nowdateKey(fristText) {
//   const date = new Date();
//   const year = String(date.getFullYear());
//   const day = String(date.getDate());
//   const month = String(date.getMonth());
//   const hour = String(date.getHours());
//   const minutes = String(date.getMinutes());

//   const fullHour =
//     (hour.length === 1 ? "0" + hour : hour) +
//     (minutes.length === 1 ? "0" + minutes : minutes);
//   const fullDate =
//     (day.length === 1 ? "0" + day : day) +
//     (month.length === 1 ? "0" + month : month) +
//     year;

//   return (fristText ? fristText + "_" : "") + fullDate + "_" + fullHour;
// }
