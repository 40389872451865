import baseService from "./baseService";

export default class Analytics extends baseService {
  constructor() {
    super();
    this.schemaName = "React/Analytics";
  }

  getTeacherReportPageLinks(entity) {
    return this.postObject(
      this.schemaName,
      "GetTeacherReportPageLinks",
      entity
    );
  }

  getTeacherReportPageLink(entity) {
    return this.postObject(this.schemaName, "GetTeacherReportPageLink", entity);
  }

  updateTeacherReportPageLink(entity) {
    return this.postObject(
      this.schemaName,
      "UpdateTeacherReportPageLink",
      entity
    );
  }

  updateTeacherReport(entity) {
    return this.postObject(this.schemaName, "UpdateTeacherReport", entity);
  }

  updateStudentReport(entity) {
    return this.postObject(this.schemaName, "UpdateStudentReport", entity);
  }

  calculateAnalytic1(entity) {
    return this.postObject(this.schemaName, "CalculateAnalytic1", entity);
  }

  calculateAnalytic2(entity) {
    return this.postObject(this.schemaName, "CalculateAnalytic2", entity);
  }

  UpdateJourneyEvaluationReport(entity) {
    return this.postObject(
      this.schemaName,
      "UpdateJourneyEvaluationReport",
      entity
    );
  }

  updateJourneyReport1(entity) {
    return this.postObject(this.schemaName, "UpdateJourneyReport1", entity);
  }
}
