import baseService from "./baseService";

export default class Logging extends baseService {
  constructor() {
    super();
    this.schemaName = "React/Logging";
  }

  getHistoryLogList(entity) {
    return this.postObject(this.schemaName, "GetHistoryLogList", entity);
  }

  getZulipLogList(entity) {
    return this.postObject(this.schemaName, "GetZulipLogList", entity);
  }

  getResetPasswordLogList(entity) {
    return this.postObject(this.schemaName, "GetResetPasswordLogList", entity);
  }
}
