import baseService from "./baseService";

export default class Content extends baseService {
  constructor() {
    super();
    this.schemaName = "React/Content";
  }

  getMenu(entity) {
    return this.postObject(this.schemaName, "GetMenu", entity);
  }
  getFooter(entity) {
    return this.postObject(this.schemaName, "GetFooter", entity);
  }
}
