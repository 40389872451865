import React, { memo, useCallback, useEffect, useMemo } from "react";
import "../css/layoutPublicMenuFooter/layoutPublicMenuFooter.scss";
import Icon from "../../../partnerComponents/icons/icon";
import { Link } from "react-router-dom";
import FooterTermsOfUse from "./footerTermsOfUse";
import { useDispatch, useSelector } from "react-redux";
import {
  privateFooterData,
  getPrivateFooter,
} from "../../../../Store/private/layout/privateFooterSlice";
import { windowOpenBlank } from "../../../utils/mixedControl";

const LayoutPublicMenuFooter = memo(() => {
  const { hasErrors, item } = useSelector(privateFooterData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!item) {
      dispatch(getPrivateFooter());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuList = useCallback((data) => {
    return (
      <div className="layoutPublicMenuFooter-main-menu">
        {data.map((item, i) => {
          return (
            <Link to={item.url} key={i}>
              <span className="layoutPublicMenuFooter-main-menu__item">
                {item.title}
              </span>
            </Link>
          );
        })}
      </div>
    );
  }, []);

  const socialMedia = useCallback((data) => {
    return (
      <div className="layoutPublicMenuFooter-main-socialMedia">
        <h5>{data.title}</h5>

        <div className="layoutPublicMenuFooter-main-socialMedia__icon">
          {data.menuItems.map((item, i) => {
            return (
              <Icon
                key={i}
                name={item.icon}
                onClick={() => windowOpenBlank(item.url)}
                // className="layoutPrivateMenuFooter-main-menu__item"
              />
            );
          })}
        </div>
      </div>
    );
  }, []);

  const componentControl = useMemo(() => {
    if (!hasErrors && item) {
      const thisYear = new Date().getFullYear();

      const { footerDown, footerLeft, footerRight } = item;

      return (
        <div className="layoutPublicMenuFooter">
          <div className="containerCenter">
            <div className="layoutPublicMenuFooter-main  ">
              <div className="layoutPublicMenuFooter-main-logo">
                <Link to="/">
                  <Icon name="icon-a-UcanAcademyLogo" />
                </Link>
                <span className="layoutPublicMenuFooter-main-logo__company">
                  © Uçanokul {thisYear}
                </span>
              </div>

              {footerLeft && menuList(footerLeft)}

              {footerRight && socialMedia(footerRight)}
            </div>
          </div>

          {footerDown && <FooterTermsOfUse termsOfUseData={footerDown} />}
        </div>
      );
    }
    return null;
  }, [hasErrors, item, menuList, socialMedia]);

  return componentControl;
});

export default LayoutPublicMenuFooter;
