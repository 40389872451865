import { clockRegex } from "../../utils/dateFormatter/clockRegex";

export function homeCalendarModel(requestData) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    return {
      items: homeCalendarItem(data),
    };
  } else {
    return {
      items: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}

function homeCalendarItem(items) {
  let calendarList = [];
  let alignmentList = [1];

  if (items.length > 0) {
    items.forEach((item, i) => {
      const { start, end, id, title, teacherName } = item;
      const startTime = clockRegex(start, true);
      const endTime = clockRegex(end, true);

      if (i !== 0) {
        alignmentList = alignmentControl(
          alignmentList,
          calendarList,
          startTime,
          endTime,
          i
        );
      }
      calendarList.push({
        id,
        startTime,
        endTime,
        title,
        teacherName,
        totalMinutes:
          (endTime.hour - startTime.hour) * 60 +
          (endTime.minutes - startTime.minutes),
      });
    });
  }

  return {
    calendarList,
    alignmentList,
    cardLengthList: cardLengthControl(alignmentList),
  };
}

const cardLengthControl = (alignmentList) => {
  const newCardLengthList = [];
  for (let i = 0; i < alignmentList.length; i++) {
    const selectItemAlignment = alignmentList[i];

    let count = 1;
    for (let k = i; k < alignmentList.length; k++) {
      const selectInnerItemAlignment = alignmentList[k + 1];

      if (selectItemAlignment === 1) {
        if (
          !selectInnerItemAlignment ||
          selectInnerItemAlignment === alignmentList[k] ||
          selectInnerItemAlignment < alignmentList[k]
        ) {
          newCardLengthList.push(100 / count);
          break;
        } else {
          count += 1;
        }
      } else {
        newCardLengthList.push(newCardLengthList[i - 1]);
        break;
      }
    }
  }
  return newCardLengthList;
};

const alignmentControl = (alignmentList, fullList, startTime, endTime, i) => {
  let newAlignment = alignmentList;
  let alignment = alignmentList[alignmentList.length - 1];

  for (let i = fullList.length - 1; i >= 0; i--) {
    const selectEndTime = fullList[i].endTime;

    if (
      selectEndTime.hour > startTime.hour ||
      (selectEndTime.hour === startTime.hour &&
        selectEndTime.minutes > startTime.minutes)
    ) {
      //eğer düşükçe bitiş saatinden + 1 yapıp döngüyü kırıyor

      newAlignment.push(alignment + 1);
      break;
    } else {
      // eğer alignmentList 1 ise zaten saat ler çakışmıyordur ozaman 1 nuamra atanır
      if (alignmentList[i] === 1) {
        newAlignment.push(1);
        break;
      } else {
        // eğer 1 ve üzeri ise - 1 yaparak sola doğru kaydıracağız kutuları
        //şuandaki hiza sırası ile bir önceki eşit değilse - 1 yapıyor
        if (alignmentList[i + 1] !== alignmentList[i]) {
          alignment -= 1;
        }
      }
    }
  }

  return newAlignment;
};
