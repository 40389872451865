import { useParams } from "react-router-dom";
import "./css/index.scss";
import Iframe from "react-iframe";

const VimeoIframe = () => {
  const params = useParams();
  if (params?.id) {
    return (
      <div className="vimeoIframe">
        <Iframe
          src={"https://player.vimeo.com/video/" + params.id}
          title="video"
          allowfullscreen
        />
      </div>
    );
  }
};

export default VimeoIframe;
