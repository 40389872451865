import React, { memo } from "react";
import { Link } from "react-router-dom";
import GetWord from "../../utils/language/getWord";
import Icon from "../icons/icon";
import PropTypes from "prop-types";
import ButtonCustom from "../button/buttonCustom";
import "./css/pageNotFound.scss";

//<PageNotFound  msgCode="" btnClose={true} pageInnerNotFound={true} logoUrl="/"/>

const PageNotFound = memo(
  ({ msgCode, btnClose, pageInnerNotFound, logoUrl }) => {
    const code = msgCode || "404";
    const newCode = String(code);
    return (
      <div className="pageNotFound">
        {!pageInnerNotFound && (
          <div className="pageNotFound-logo">
            <Link to={logoUrl || "/"}>
              <Icon name="icon-a-UcanAcademyLogo" />
            </Link>
          </div>
        )}

        <div className="pageNotFound-text">
          <div className="pageNotFound-text-container">
            <div className="pageNotFound-text-container-errCode">
              <span>{newCode[0]}</span>
              <span>{newCode[1]}</span>
              <span>{newCode[2]}</span>
            </div>
            <h1 className="pageNotFound-text-container__title">
              {msgCode === 500
                ? "Server Erişilemiyor"
                : GetWord("PageNotFound")}
            </h1>
            {!btnClose && (
              <Link to="/">
                <ButtonCustom
                  label="btnHomePage"
                  btnColor="blue"
                  width="auto"
                  iconName="icon-back"
                  iconPosition="left"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
);

PageNotFound.propTypes = {
  msgCode: PropTypes.string,
  btnClose: PropTypes.bool,
  pageInnerNotFound: PropTypes.bool,
  logoUrl: PropTypes.string,
};

export default PageNotFound;
