import moment from "moment";
import { GetI18n } from "../../utils/mixedControl";

export const privateFooterModel = (data) => {
  return {
    footerDown: footerDownList(data.footerDown?.menuItems),
    footerLeft: footerLeftList(data.footerLeft?.menuItems),
    footerRight: footerRightList(data.footerRight),
    lastCheckDate: moment().format("YYYY-MM-DD"),
  };
};

//alt taraf metinler
const footerDownList = (items) => {
  let newList = [];
  if (items?.length > 0) {
    newList = items.map((item) => {
      return {
        title: GetI18n(item, "title"),
        description: GetI18n(item, "description"),
      };
    });
  }

  return newList;
};

// menu
const footerLeftList = (items) => {
  let newList = [];
  if (items?.length > 0) {
    newList = items.map((item) => {
      return {
        title: GetI18n(item, "title"),
        url: GetI18n(item, "url"),
      };
    });
  }

  return newList;
};

//sosyal medya bölümü
const footerRightList = (data) => {
  if (data?.menuItems?.length > 0) {
    let menuItems = [];
    menuItems = data.menuItems.map((item) => {
      return {
        url: GetI18n(item, "url"),
        icon: item.icon,
      };
    });

    return {
      title: GetI18n(data, "title"),
      menuItems,
    };
  }
  return null;
};
