import React, { memo } from "react";
import { Link } from "react-router-dom";
import Icon from "../icons/icon";

const PublicBreadCrumb = memo(({ pageName }) => {
  return (
    <div className="containerCenter">
      <div className="publicBreadcrumb">
        <div className="publicBreadcrumb-menu">
          <Link to="/">Ana Sayfa</Link>
          <Icon name="icon-jiantou-" className="publicBreadcrumb-separator" />
          <span>{pageName}</span>
        </div>
        <h1>{pageName}</h1>
      </div>
    </div>
  );
});

export default PublicBreadCrumb;
