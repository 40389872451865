import { GetI18n } from "../../utils/mixedControl";

export const pageModel = (data) => {
  return {
    headline: getHeadline(data.headlines[0]),
    widgets: getWidgets(data.widgets),
  };
};

const getHeadline = (item) => {
  if (item) {
    return {
      name: GetI18n(item, "name"),
      description: GetI18n(item, "description"),
      imagePath: item.imagePath,
    };
  }
};

const getWidgets = (items) => {
  let newList = [];

  if (items?.length > 0) {
    newList = items.map((item) => {
      const {
        name,
        subName,
        description,
        code,
        documentType,
        courseUrl,
        popupUrl,
        imagePath,
        widgetContents,
      } = item;
      return {
        name: GetI18n(item, "name"),
        subName: GetI18n(item, "subName"),
        description: GetI18n(item, "description"),
        code,
        documentType,
        courseUrl,
        popupUrl,
        imagePath,
        widgetContents:
          widgetContents.length > 0
            ? getWidgetContents(name, widgetContents)
            : [{}],
      };
    });
  }
  return newList;
};

const getWidgetContents = (name, items) => {
  let newList = [];
  if (items?.length > 0) {
    newList = items.map((item) => {
      const {
        id,
        parentWidgetContentID,
        name,
        subName,
        description,
        code,
        backgroundColor,
        courseUrl,
        documentType,
        popupUrl,
        icon,
        iconColor,
        textColor,
        imagePath,
      } = item;
      return {
        id,
        parentWidgetContentID,
        name: name && GetI18n(item, "name"),
        subName: subName && GetI18n(item, "subName"),
        description: description && GetI18n(item, "description"),
        code,
        backgroundColor,
        courseUrl,
        documentType,
        popupUrl,
        icon,
        iconColor,
        textColor,
        imagePath,
      };
    });
  }
  return newList;
};
