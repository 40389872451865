import baseService from "./baseService";

export default class Visa extends baseService {
  constructor() {
    super();
    this.schemaName = "Visa";
    this.schemaNameReact = "React/Visa";
  }

  login(entity) {
    return this.postObject(this.schemaName, "Login", entity);
  }

  getRecoveryType(entity) {
    return this.postObject(this.schemaNameReact, "GetRecoveryType", entity);
  }

  sendRecovery(entity) {
    return this.postObject(this.schemaNameReact, "SendRecovery", entity);
  }

  resetPassword(entity) {
    return this.postObject(this.schemaNameReact, "ResetPassword", entity);
  }

  logout(entity) {
    return this.postObject(this.schemaName, "Logout", entity);
  }

  getMyProfile(entity) {
    return this.postObject(this.schemaNameReact, "GetMyProfile", entity);
  }

  getUserAvatarData(entity) {
    return this.postObject(this.schemaName, "GetUserAvatarData", entity);
  }

  changeAvatar(entity) {
    return this.postObject(this.schemaName, "ChangeAvatar", entity);
  }

  getUserList(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetUserList",
      entity,
      cancelToken
    );
  }

  addNewUser(entity) {
    return this.postObject(this.schemaNameReact, "AddNewUser", entity);
  }

  getUserHeader(entity) {
    return this.postObject(this.schemaNameReact, "getUserHeader", entity);
  }

  getUserInformation(entity) {
    return this.postObject(this.schemaNameReact, "GetUserInformation", entity);
  }

  updateUser(entity) {
    return this.postObject(this.schemaNameReact, "UpdateUser", entity);
  }

  createTemporaryPassword(entity) {
    return this.postObject(
      this.schemaNameReact,
      "CreateTemporaryPassword",
      entity
    );
  }

  getUserCompanyGroups(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetUserCompanyGroups",
      entity
    );
  }

  getUserEducations(entity) {
    return this.postObject(this.schemaNameReact, "GetUserEducations", entity);
  }

  getUserHistories(entity) {
    return this.postObject(this.schemaNameReact, "GetUserHistories", entity);
  }

  getUserReports(entity) {
    return this.postObject(this.schemaNameReact, "GetUserReports", entity);
  }

  getIsLiveRoomAuthority(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetIsLiveRoomAuthority",
      entity
    );
  }

  exportCompanyUsers(entity) {
    return this.postObject(this.schemaNameReact, "ExportCompanyUsers", entity);
  }

  IsGlobalEditAuthority(entity) {
    return this.postObject(
      this.schemaNameReact,
      "IsGlobalEditAuthority",
      entity
    );
  }

  registerUser(entity) {
    return this.postObject(this.schemaNameReact, "RegisterUser", entity);
  }

  sendUserInfoMail(entity) {
    return this.postObject(this.schemaNameReact, "SendUserInfoMail", entity);
  }

  sendUserInfoSms(entity) {
    return this.postObject(this.schemaNameReact, "SendUserInfoSms", entity);
  }

  GetUser(entity) {
    return this.postObject(this.schemaNameReact, "GetUser", entity);
  }

  updateResetPasswordOpenLog(entity) {
    return this.postObject(
      this.schemaNameReact,
      "UpdateResetPasswordOpenLog",
      entity
    );
  }

  sendValidateCode(entity) {
    return this.postObject(this.schemaNameReact, "SendValidateCode", entity);
  }

  saveValidated(entity) {
    return this.postObject(this.schemaNameReact, "SaveValidated", entity);
  }

  saveUserName(entity) {
    return this.postObject(this.schemaNameReact, "SaveUserName", entity);
  }

  updateUserFromProfile(entity) {
    return this.postObject(
      this.schemaNameReact,
      "UpdateUserFromProfile",
      entity
    );
  }

  sendRecoveryFromUserID(entity) {
    return this.postObject(
      this.schemaNameReact,
      "SendRecoveryFromUserID",
      entity
    );
  }

  changeEmailorPhone(entity) {
    return this.postObject(this.schemaNameReact, "ChangeEmailorPhone", entity);
  }

  changePassword(entity) {
    return this.postObject(this.schemaNameReact, "ChangePassword", entity);
  }

  ValidateUserName(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "ValidateUserName",
      entity,
      cancelToken
    );
  }

  ValidateIdentityNumber(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "ValidateIdentityNumber",
      entity,
      cancelToken
    );
  }

  getZulipFrameUrl(entity) {
    return this.postObject(this.schemaNameReact, "GetZulipFrameUrl", entity);
  }

  IsChatAuthority(entity) {
    return this.postObject(this.schemaNameReact, "IsChatAuthority", entity);
  }

  deleteUsers(entity) {
    return this.postObject(this.schemaNameReact, "DeleteUsers", entity);
  }

  getRegisterUserValidate(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetRegisterUserValidate",
      entity
    );
  }

  sendRegisterUserValidateCode(entity) {
    return this.postObject(
      this.schemaNameReact,
      "SendRegisterUserValidateCode",
      entity
    );
  }

  saveRegisterUserValidated(entity) {
    return this.postObject(
      this.schemaNameReact,
      "SaveRegisterUserValidated",
      entity
    );
  }
}
