import { createSlice } from "@reduxjs/toolkit";
import Service from "../../../Service";
import { privateFooterModel } from "../../../Shared/models";
import { initialObjectState } from "../../initialState";
import {
  getLocalstorage,
  setLocalstorage,
} from "../../../Shared/utils/localStore/localStorage";
import moment from "moment";

const privateFooterSlice = createSlice({
  name: "privateFooter",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.hasErrors = false;
    },
    failed: (state) => {
      state.hasErrors = true;
    },
  },
});

export const privateFooterData = (state) => state.privateFooter; // initialState üzerindeki bilgileri dışarı aktarma

export const { setItemList, failed } = privateFooterSlice.actions; // functions dışarıya aktarılması

export default privateFooterSlice.reducer;

export function getPrivateFooter() {
  return async (dispatch) => {
    if (
      // getfooter apisi localStorage'a yazıldı. apiye günde 1 kez istek atması için tarih eklendi.
      getLocalstorage("privateFooter") &&
      getLocalstorage("privateFooter").lastCheckDate ===
        moment().format("YYYY-MM-DD")
    ) {
      dispatch(setItemList(getLocalstorage("privateFooter")));
    } else {
      const service = new Service();
      const requestData = {};

      service.content.getFooter(requestData).then((res) => {
        if (res?.data && !res.hasFailed) {
          const newData = privateFooterModel(res.data);
          setLocalstorage("privateFooter", newData);
          dispatch(setItemList(newData));
        } else {
          dispatch();
        }
      });
    }
  };
}
