import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import avatarSlice from "./private/avatar/avatarSlice";
import homeSlice from "./private/home/homeSlice";
import layoutPrivateMenuSlice from "./private/layout/layoutPrivateMenuSlice";
import privateFooterSlice from "./private/layout/privateFooterSlice";
import myProfileSlice from "./private/myProfile/myProfileSlice";
import clarificationTextSlice from "./public/clarificationTextSlice";
import privacyPolicySlice from "./public/privacyPolicySlice";
import reduxResetSlice from "./general/reduxResetSlice";
import aboutSlice from "./public/aboutSlice";
import contactSlice from "./public/contactSlice";
import requestDemoSlice from "./public/requestDemoSlice";
import welcomeSlice from "./public/welcomeSlice";
import courseViewTypeSlice from "./private/education/courseViewTypeSlice";
import translationResourceSlice from "./general/translationResourceSlice";
import translationPostSlice from "./general/translationPostSlice";
import layoutPrivateMobileMenuSlice from "./private/layout/layoutPrivateMobileMenuSlice";
import chatSlice from "./private/chat/chatSlice";

const combinedReducer = combineReducers({
  translation: translationResourceSlice,
  translationPost: translationPostSlice,
  siteSidebarMenu: layoutPrivateMenuSlice,
  siteMobileMenu: layoutPrivateMobileMenuSlice,
  home: homeSlice,
  privateFooter: privateFooterSlice,
  avatar: avatarSlice,
  myProfile: myProfileSlice,
  welcome: welcomeSlice,
  about: aboutSlice,
  contact: contactSlice,
  requestDemo: requestDemoSlice,
  clarificationText: clarificationTextSlice,
  privacyPolicy: privacyPolicySlice,
  courseViewType: courseViewTypeSlice,
  reduxReset: reduxResetSlice,
  chatRedux: chatSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "redux_reset/setItemList") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
});
