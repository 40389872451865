import { createSlice } from "@reduxjs/toolkit";
import {
  getLocalstorage,
  setLocalstorage,
} from "../../../Shared/utils/localStore/localStorage";

const chatSlice = createSlice({
  name: "chatRedux",
  initialState: {
    isChatClose: null,
  },
  reducers: {
    setIsChatClose: (state, { payload }) => {
      setLocalstorage("isChatClose", !payload);
      state.isChatClose = !payload;
    },
  },
});

export const isChatCloseData = (state) => state.chatRedux;
export const { setIsChatClose } = chatSlice.actions; // functions dışarıya aktarılması
export default chatSlice.reducer;

export function getIsChatClose() {
  return async (dispatch) => {
    if (getLocalstorage("isChatClose") !== null) {
      dispatch(setIsChatClose(!getLocalstorage("isChatClose")));
    } else {
      dispatch(setIsChatClose(false));
    }
  };
}
