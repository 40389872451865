export function oldCourseLessonsModel(requestData) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    const { courseLessons, pagination } = data;
    return {
      item: {
        courseLessons,
        pagination,
      },
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}
