import React, { memo, useMemo } from "react";
import { Button } from "antd";
import GetWord from "../../utils/language/getWord";
import Loading from "../loading/loading";
import PropTypes from "prop-types";
import Icon from "../icons/icon";

//<ButtonCustom
// label="btnDatePicker"
// className=""
// disabled={false}
// loadingActive={true}
// textColor=""
// backgroundColor=""
// btnColor="angularBlue"  / "red" / "blue" / "yellow" / "angularRed" / "angularGreen"
// onClick={() => console.log("1")}
// style={{ width: 200 }}
// width={500}
// iconName=""
// iconPosition="left" / "right"
// />

const ButtonCustom = memo(
  ({
    label,
    backgroundColor,
    className,
    loadingActive,
    textColor,
    disabled,
    btnColor,
    onClick,
    style,
    width,
    iconName,
    iconPosition,
  }) => {
    const btnClassControl = useMemo(() => {
      switch (btnColor) {
        case "yellow":
          return "ant-yellowBtn";
        case "angularGreen":
          return "ant-angularGreen";

        case "red":
          return "ant-redBtn";

        case "angularBlue":
          return "ant-angularBlue";

        case "angularRed":
          return "ant-angularRed";

        case "blue":
          return "ant-blueBtn";

        case "white":
          return "ant-whiteBtn";

        case "customHoverless":
          return "ant-custom-button-hoverless";

        default:
          return "ant-redBtn";
      }
    }, [btnColor]);

    return (
      <div
        className={"formSubmitButton " + (className ? className : "")}
        style={{ width }}
      >
        <Button
          type="primary"
          htmlType="button"
          style={{ backgroundColor, ...style }}
          disabled={loadingActive || disabled}
          className={btnClassControl}
          onClick={onClick}
        >
          {iconName && iconPosition === "left" && (
            <Icon name={iconName} className="formSubmitButton-left" />
          )}
          <span style={{ color: textColor }}>{GetWord(label)}</span>
          {iconName && iconPosition === "right" && (
            <Icon name={iconName} className="formSubmitButton-right" />
          )}
        </Button>
        {loadingActive && <Loading innerPageActive={true} size="small" />}
      </div>
    );
  }
);

ButtonCustom.propTypes = {
  label: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  loadingActive: PropTypes.bool,
  textColor: PropTypes.string,
  disabled: PropTypes.bool,
  btnColor: PropTypes.string,
  iconName: PropTypes.string,
  iconPosition: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  width: PropTypes.node,
};

export default ButtonCustom;
