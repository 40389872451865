import baseService from "./baseService";

export default class ConfigurationService extends baseService {
  constructor() {
    super();
    this.schemaName = "Configuration";
    this.schemaNameReact = "React/Configuration";
  }

  initApp(entity) {
    return this.postObject(this.schemaNameReact, "InitApp", entity);
  }

  getTranslationResource(entity) {
    return this.getObject(this.schemaName, "GetTranslationResource", entity);
  }

  updateTranslation(entity) {
    return this.postObject(this.schemaNameReact, "UpdateTranslation", entity);
  }

  getMeetingServers(entity) {
    return this.postObject(this.schemaNameReact, "GetMeetingServers", entity);
  }

  getServerConfiguration(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetServerConfiguration",
      entity
    );
  }

  getBBBMachineLogs(entity) {
    return this.postObject(this.schemaNameReact, "GetBBBMachineLogs", entity);
  }

  createServer(entity) {
    return this.postObject(this.schemaNameReact, "CreateServer", entity);
  }

  runAllBBBService(entity) {
    return this.postObject(this.schemaNameReact, "RunAllBBBService", entity);
  }

  meetingKeeperService(entity) {
    return this.postObject(
      this.schemaNameReact,
      "MeetingKeeperService",
      entity
    );
  }

  machineEnhancerService(entity) {
    return this.postObject(
      this.schemaNameReact,
      "MachineEnhancerService",
      entity
    );
  }

  machineReducerService(entity) {
    return this.postObject(
      this.schemaNameReact,
      "MachineReducerService",
      entity
    );
  }

  resetServer(entity) {
    return this.postObject(this.schemaNameReact, "ResetServer", entity);
  }

  changeFile(entity) {
    return this.postObject(this.schemaNameReact, "ChangeFile", entity);
  }

  getDataCenterList(entity) {
    return this.postObject(this.schemaNameReact, "GetDataCenterList", entity);
  }

  getDataCenter(entity) {
    return this.postObject(this.schemaNameReact, "GetDataCenter", entity);
  }

  updateDataCenter(entity) {
    return this.postObject(this.schemaNameReact, "UpdateDataCenter", entity);
  }

  increaseTranslationAccesses(entity) {
    return this.postObject(
      this.schemaNameReact,
      "IncreaseTranslationAccesses",
      entity
    );
  }

  getServerReports(entity) {
    return this.postObject(this.schemaNameReact, "GetServerReports", entity);
  }

  uploadTranslation(entity) {
    return this.postObject(this.schemaNameReact, "UploadTranslation", entity);
  }

  getTranslations(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetTranslations",
      entity,
      cancelToken
    );
  }
}
