import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingCorporate from "../../../Shared/partnerComponents/loading/loadingCorporate";
import PublicPageHeader from "../../../Shared/partnerComponents/publicPageHeader";
import {
  getPrivacyPolicyPage,
  privacyPolicySelector,
} from "../../../Store/public/privacyPolicySlice";
import "./css/privacyPolicy.scss";

const PrivacyPolicy = () => {
  const { item, hasErrors } = useSelector(privacyPolicySelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!item) dispatch(getPrivacyPolicyPage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const componentControl = useMemo(() => {
    if (!hasErrors && item) {
      const { widgets, headline } = item;
      return (
        <>
          {headline && <PublicPageHeader item={headline} />}
          {widgets && (
            <div className="privacyPolicy-inner">
              <div
                className="privacyPolicy-inner__title"
                dangerouslySetInnerHTML={{ __html: widgets[0].name }}
              />
              <div
                className="privacyPolicy-inner__description"
                dangerouslySetInnerHTML={{ __html: widgets[0].description }}
              />
            </div>
          )}
        </>
      );
    } else {
      return <LoadingCorporate />;
    }
  }, [item, hasErrors]);

  return <div className="privacyPolicyPage">{componentControl}</div>;
};

export default PrivacyPolicy;
