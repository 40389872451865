import baseService from "./baseService";

export default class CommCo extends baseService {
  constructor() {
    super();
    this.schemaName = "React/CommCo";
  }

  // recoverZulip(entity) {
  //   return this.postObject(this.schemaName, "RecoverZulip", entity);
  // }

  updateRelation(entity) {
    return this.postObject(this.schemaName, "UpdateRelation", entity);
  }
}
