import { createSlice } from "@reduxjs/toolkit";
import Service from "../../Service";
import { pageModel } from "../../Shared/models";
import { initialObjectState } from "../initialState";

const requestDemoSlice = createSlice({
  name: "requestDemo",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
});

export const requestDemoSelector = (state) => state.requestDemo; // initialState üzerindeki bilgileri dışarı aktarma
export const { setItemList, failed } = requestDemoSlice.actions; // functions dışarıya aktarılması
export default requestDemoSlice.reducer;

export function getRequestDemoPage() {
  return async (dispatch) => {
    const service = new Service();
    const requestData = { data: { code: "requestDemo" } };
    service.react.getPage(requestData).then((res) => {
      if (res?.data && !res.hasFailed) {
        const newData = pageModel(res.data);
        dispatch(setItemList(newData));
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    });
  };
}
