import { clockRegex } from "../../utils/dateFormatter/clockRegex";
import { dateRequestFormat } from "../../utils/dateFormatter/dateRegex";

// validasyon edilmiş datanın içerisinde değişken isimleri içerisinde time ve date arıyarak liste yapıyor ve geri dönüyor
//formun api gönderilme anında kullanılır.
export const apiRequestDateAndTimeModel = (validasyonData) => {
  let newDateAndTimeData = {};
  for (var prop in validasyonData) {
    const newProp = prop.toLocaleLowerCase();
    const value = validasyonData[prop];

    if (newProp.indexOf("time") > -1) {
      if (value) {
        //eğer time varsa formatlıyor ve obje ekliyor - bugunun tarihi ile
        newDateAndTimeData = {
          ...newDateAndTimeData,
          [prop]: apiRequestMomentModel(new Date(), value),
        };
      } else {
        //eğer time yoksa  null ekliyor
        newDateAndTimeData = { ...newDateAndTimeData, [prop]: null };
      }
    } else if (newProp.indexOf("date") > -1) {
      if (value) {
        //eğer date varsa formatlıyor ve obje eklyor
        newDateAndTimeData = {
          ...newDateAndTimeData,
          [prop]: apiRequestMomentModel(value),
        };
      } else {
        //eğer date yoksa  null ekliyor
        newDateAndTimeData = { ...newDateAndTimeData, [prop]: null };
      }
    }
  }
  return newDateAndTimeData;
};

//date  ve time request yaparken time zone çeviriyor istersek sadece date veya date time birleştirmesi de yapıyor
export function apiRequestMomentModel(date, time) {
  if (date) {
    const clock = time ? clockRegex(time._d) : null;
    return dateRequestFormat(date._d, clock);
  }
  return null;
}

//multi selectleri validasyondan gelen datayı istenilen türe çevirip servise datasına yolluyor.
export const apiRequestMultiSelectionModel = (validasyonData, objeName) => {
  let newMutliSelectionData = [];

  if (validasyonData && objeName) {
    validasyonData.forEach((item) => {
      newMutliSelectionData.push({
        [objeName]: item,
      });
    });
    return newMutliSelectionData;
  }

  return null;
};
