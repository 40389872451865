import React, { memo } from "react";
import PropTypes from "prop-types";
import GetWord from "../../utils/language/getWord";

//  <Icon name="" style={{}} className="" classNameSvg="" onClick={func} title=""/>

const Icon = memo(
  ({ name, style, classNameSvg, onClick, title, className }) => {
    return (
      <div
        style={{ fontSize: "1px" }}
        title={title && GetWord(title)}
        className={className || ""}
      >
        <svg
          className={"icon svg-icon " + (classNameSvg || "")}
          aria-hidden="true"
          style={style}
          onClick={onClick}
        >
          <use href={"#" + name}></use>
        </svg>
      </div>
    );
  }
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  classNameSvg: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default Icon;
