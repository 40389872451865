import baseService from "./baseService";

export default class Search extends baseService {
  constructor() {
    super();
    this.schemaName = "React/Search";
  }

  getSearchProfileList(entity, cancelToken) {
    return this.postObject(
      this.schemaName,
      "GetSearchProfileList",
      entity,
      cancelToken
    );
  }

  getSearchProfileListMobile(entity, cancelToken) {
    return this.postObject(
      this.schemaName,
      "GetSearchProfileListMobile",
      entity,
      cancelToken
    );
  }
  updateSearchProfileView(entity) {
    return this.postObject(this.schemaName, "updateSearchProfileView", entity);
  }
}
