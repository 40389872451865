import { createSlice } from "@reduxjs/toolkit";
import Service from "../../../Service";
import { layoutPrivateMenuModel } from "../../../Shared/models";
import { Decrypt } from "../../../Shared/utils/encrypted";
import {
  getLocalstorage,
  removeLocalstorage,
  setLocalstorage,
} from "../../../Shared/utils/localStore/localStorage";
import { initialListState } from "../../initialState";
import moment from "moment";
import { languageIDGetNew } from "../../../Shared/utils/language/languageIDGet";

const layoutPrivateMenuSlice = createSlice({
  name: "siteSidebarMenu",
  initialState: initialListState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.items = payload;
    },
  },
});

export const leftMenu = (state) => state.siteSidebarMenu;
export const { setItemList } = layoutPrivateMenuSlice.actions;
export default layoutPrivateMenuSlice.reducer;

export function getSiteSidebarMenu() {
  return async (dispatch) => {
    if (
      getLocalstorage("siteSidebarMenu")?.siteSidebar.filter(
        (op) => op.code === "mesajBoard"
      ) &&
      getLocalstorage("chatSiteUrl") === null
    ) {
      removeLocalstorage("siteSidebarMenu");
    }

    // if (
    //   // getSiteSidebarMenu apisi localStorage'a yazıldı. apiye günde 1 kez istek atması için tarih eklendi.
    //   getLocalstorage("siteSidebarMenu") &&
    //   getLocalstorage("siteSidebarMenu").lastCheckDate ===
    //     moment().format("YYYY-MM-DD")
    // ) {
    //   dispatch(setItemList(getLocalstorage("siteSidebarMenu").siteSidebar));
    // } else {
    //   const service = new Service();
    //   const requestData = {
    //     languageID: languageIDGetNew(),
    //     data: {
    //       code: "siteSidebar",
    //       userTypeID: getLocalstorage("userTypeID")
    //         ? Decrypt(getLocalstorage("userTypeID")) === "guest"
    //           ? 0
    //           : Decrypt(getLocalstorage("userTypeID"))
    //         : 0,
    //     },
    //   };
    //   service.content.getMenu(requestData).then((res) => {
    //     if (res?.data && !res.hasFailed) {
    //       const newData = layoutPrivateMenuModel(res.data.menuDetails);
    //       setLocalstorage("siteSidebarMenu", {
    //         siteSidebar: newData,
    //         lastCheckDate: moment().format("YYYY-MM-DD"),
    //       });
    //       dispatch(setItemList(newData));
    //     }
    //   });
    // }

    const service = new Service();
    const requestData = {
      languageID: languageIDGetNew(),
      data: {
        code: "siteSidebar",
        userTypeID: getLocalstorage("userTypeID")
          ? Decrypt(getLocalstorage("userTypeID")) === "guest"
            ? 0
            : Decrypt(getLocalstorage("userTypeID"))
          : 0,
      },
    };
    service.content.getMenu(requestData).then((res) => {
      if (res?.data && !res.hasFailed) {
        const newData = layoutPrivateMenuModel(res.data.menuDetails);
        setLocalstorage("siteSidebarMenu", {
          siteSidebar: newData,
          lastCheckDate: moment().format("YYYY-MM-DD"),
        });
        dispatch(setItemList(newData));
      }
    });
  };
}
