import React, { memo } from "react";
import { Spin } from "antd";
import PropTypes from "prop-types";
import LoadingGif from "../../../Assets/images/icons/loading.gif";
import "./css/loading.scss";

//<Loading size="" innerPageActive={true}/>

// size = middle / small /large
// innerPageActive = sayfa içerisinde arka plana pusluk yapıyor

const Loading = memo((props) => {
  const { size, innerPageActive, isStandart, style } = props;
  return (
    <div
      className={
        "loading  " +
        (innerPageActive ? "loading--innerPage" : "") +
        (isStandart ? "loading-inherit " : "")
      }
      style={style}
    >
      <img src={LoadingGif} alt="loading" />
    </div>
  );
});

Loading.propTypes = {
  size: PropTypes.string,
  innerPageActive: PropTypes.bool,
};

export default Loading;
