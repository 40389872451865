export function coursesModel(requestData) {
  const data = requestData ? requestData.data : false;

  if (data && !requestData.hasFailed) {
    const { breadcrumbs, course, subCourses } = data;
    return {
      item: {
        breadcrumbs,
        course,
        subCourses,
      },
    };
  } else {
    return {
      item: false,
      hasErrors: true,
      hasMessage: requestData?.messages?.[0]?.description,
    };
  }
}
