import axios from "axios";
import { getLocalstorage } from "../Shared/utils/localStore/localStorage";

export default class baseService {
  postObject(schemaName, funcName, entity, cancelToken) {
    var url = global.apiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "POST", cancelToken);
  }

  postToUrl(requesUrl, entity, cancelToken) {
    var url = global.apiURL + requesUrl;
    return this.callServiceMethod(url, entity, "POST", cancelToken);
  }

  getObject(schemaName, funcName, data) {
    var url =
      global.apiURL + schemaName + "/" + funcName + "?" + (data ? data : "");
    return this.callServiceMethod(url, data, "GET");
  }

  callServiceMethod(url, data, method, cancelToken) {
    var token = getLocalstorage("token");

    var headers = {
      Authorization: token ? "Bearer " + token : "",
    };

    return this.callAnyServiceMethod(url, data, method, headers, cancelToken);
  }

  async callAnyServiceMethod(url, data, method, headers, cancelToken) {
    var authOptions = {
      method,
      url,
      data,
      headers,
      withCredentials: true,
      cancelToken,
    };

    return await axios(authOptions)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
