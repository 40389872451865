import baseService from "./baseService";

export default class Organization extends baseService {
  constructor() {
    super();
    this.schemaName = "Organization";
    this.schemaNameReact = "React/Organization";
  }

  getUserEvents(entity) {
    return this.postObject(this.schemaNameReact, "GetUserEvents", entity);
  }

  getUserEventsMobile(entity) {
    return this.postObject(this.schemaNameReact, "GetUserEventsMobile", entity);
  }

  GetUserCalendarHeaderMobile(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetUserCalendarHeaderMobile",
      entity
    );
  }

  getCalendarDetailPage(entity) {
    return this.postObject(this.schemaName, "GetCalendarDetailPage", entity);
  }

  completeLessonEvent(entity) {
    return this.postObject(this.schemaName, "CompleteLessonEvent", entity);
  }

  cancelLessonEvent(entity) {
    return this.postObject(this.schemaName, "CancelLessonEvent", entity);
  }

  updateRequestDemo(entity) {
    return this.postObject(this.schemaNameReact, "UpdateRequestDemo", entity);
  }

  getCompanyList(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetCompanyList",
      entity,
      cancelToken
    );
  }

  getCompany(entity) {
    return this.postObject(this.schemaNameReact, "GetCompany", entity);
  }

  getAddNewCompany(entity) {
    return this.postObject(this.schemaNameReact, "GetAddNewCompany", entity);
  }

  updateCompany(entity) {
    return this.postObject(this.schemaNameReact, "updateCompany", entity);
  }

  getCompanyGroups(entity) {
    return this.postObject(this.schemaNameReact, "GetCompanyGroups", entity);
  }

  getUserTypes(entity) {
    return this.postObject(this.schemaNameReact, "GetUserTypes", entity);
  }

  getCompanyGroup(entity) {
    return this.postObject(this.schemaNameReact, "GetCompanyGroup", entity);
  }

  updateCompanyGroup(entity) {
    return this.postObject(this.schemaNameReact, "UpdateCompanyGroup", entity);
  }

  exportCompanyGroup(entity) {
    return this.postObject(this.schemaNameReact, "ExportCompanyGroup", entity);
  }

  getCompanyGroupHeader(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetCompanyGroupHeader",
      entity
    );
  }

  getCompanyGroupUsers(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetCompanyGroupUsers",
      entity
    );
  }

  getAssignUser(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetAssignUser",
      entity,
      cancelToken
    );
  }

  updateCompanyGroupUsers(entity) {
    return this.postObject(
      this.schemaNameReact,
      "UpdateCompanyGroupUsers",
      entity
    );
  }

  addCompanyGroupToUser(entity) {
    return this.postObject(
      this.schemaNameReact,
      "AddCompanyGroupToUser",
      entity
    );
  }

  transferUserGroup(entity) {
    return this.postObject(this.schemaNameReact, "TransferUserGroup", entity);
  }

  getCompanyGroupJourneys(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetCompanyGroupJourneys",
      entity
    );
  }

  resetLoginData(entity) {
    return this.postObject(this.schemaNameReact, "ResetLoginData", entity);
  }

  resetAuthority(entity) {
    return this.postObject(this.schemaNameReact, "ResetAuthority", entity);
  }

  resetGroupAndUserCount(entity) {
    return this.postObject(
      this.schemaNameReact,
      "ResetGroupAndUserCount",
      entity
    );
  }

  getCompanyGroupHistories(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetCompanyGroupHistories",
      entity
    );
  }

  addMultiUser(entity) {
    return this.postObject(this.schemaNameReact, "AddMultiUser", entity);
  }

  multiOrganizationReview(entity) {
    return this.postObject(
      this.schemaNameReact,
      "MultiOrganizationReview",
      entity
    );
  }

  multiOrganization(entity) {
    return this.postObject(this.schemaNameReact, "MultiOrganization", entity);
  }

  getCompanyGroupRelations(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetCompanyGroupRelations",
      entity,
      cancelToken
    );
  }

  getAddNewCompanyGroupRelation(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetAddNewCompanyGroupRelation",
      entity
    );
  }

  getCompanyGroupAuthorities(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetCompanyGroupAuthorities",
      entity
    );
  }

  updateCompanyGroupAuthority(entity) {
    return this.postObject(
      this.schemaNameReact,
      "UpdateCompanyGroupAuthority",
      entity
    );
  }

  getUserAndGroup(entity) {
    return this.postObject(this.schemaNameReact, "GetUserAndGroup", entity);
  }

  getRoomTeachers(entity) {
    return this.postObject(this.schemaNameReact, "GetRoomTeachers", entity);
  }

  addNewCalendar(entity) {
    return this.postObject(this.schemaNameReact, "AddNewCalendar", entity);
  }

  addUserToRoom(entity) {
    return this.postObject(this.schemaNameReact, "AddUserToRoom", entity);
  }

  getCalendarDetail(entity) {
    return this.postObject(this.schemaNameReact, "GetCalendarDetail", entity);
  }
  GetCalendarDetailMobile(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetCalendarDetailMobile",
      entity
    );
  }
  getEditCalendar(entity) {
    return this.postObject(this.schemaNameReact, "GetEditCalendar", entity);
  }

  chechActiveRoom(entity) {
    return this.postObject(this.schemaNameReact, "ChechActiveRoom", entity);
  }

  CheckActiveRoomMobile(entity) {
    return this.postObject(
      this.schemaNameReact,
      "CheckActiveRoomMobile",
      entity
    );
  }

  getUserBookmarkAndLikes(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetUserBookmarkAndLikes",
      entity
    );
  }

  getUserRelations(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetUserRelations",
      entity,
      cancelToken
    );
  }

  addNewUserRelation(entity) {
    return this.postObject(this.schemaNameReact, "AddNewUserRelation", entity);
  }

  getUserImportLog(entity) {
    return this.postObject(this.schemaNameReact, "GetUserImportLog", entity);
  }

  getNewCalendar(entity) {
    return this.postObject(this.schemaNameReact, "GetNewCalendar", entity);
  }

  getRequestDemoList(entity) {
    return this.postObject(this.schemaNameReact, "getRequestDemoList", entity);
  }

  getCompanyTemplateList(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetCompanyTemplateList",
      entity
    );
  }
  GetWaitCompany(entity) {
    return this.postObject(this.schemaNameReact, "GetWaitCompany", entity);
  }
  CompanyCopyFromTemplate(entity) {
    return this.postObject(
      this.schemaNameReact,
      "CompanyCopyFromTemplate",
      entity
    );
  }

  deleteUsersFromGroup(entity) {
    return this.postObject(
      this.schemaNameReact,
      "DeleteUsersFromGroup",
      entity
    );
  }

  getSelectCompanyGroup(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetSelectCompanyGroup",
      entity
    );
  }

  getTagListForOrganization(entity, cancelToken) {
    return this.postObject(
      this.schemaNameReact,
      "GetTagListForOrganization",
      entity,
      cancelToken
    );
  }

  resetUserChatCompanyGroups(entity) {
    return this.postObject(
      this.schemaNameReact,
      "ResetUserChatCompanyGroups",
      entity
    );
  }

  resetChatCompanies(entity) {
    return this.postObject(this.schemaNameReact, "ResetChatCompanies", entity);
  }

  resetChatCompanyGroups(entity) {
    return this.postObject(
      this.schemaNameReact,
      "ResetChatCompanyGroups",
      entity
    );
  }

  companyCopyDemoStart(entity) {
    return this.postObject(
      this.schemaNameReact,
      "CompanyCopyDemoStart",
      entity
    );
  }

  synchronizeChatOrganizationsAndGroups(entity) {
    return this.postObject(
      this.schemaNameReact,
      "SynchronizeChatOrganizationsAndGroups",
      entity
    );
  }

  getTagsWithCompanyContentTag(entity) {
    return this.postObject(
      this.schemaNameReact,
      "GetTagsWithCompanyContentTag",
      entity
    );
  }

  updateCompanyContentTag(entity) {
    return this.postObject(
      this.schemaNameReact,
      "UpdateCompanyContentTag",
      entity
    );
  }
}
