import LayoutPublicHeader from "./layoutPublicHeader";
import LayoutPublicMenuFooter from "./layoutPublicMenuFooter";
import { Outlet } from "react-router-dom";

const LayoutPublic = () => {
  return (
    <div className="layoutPublic">
      <LayoutPublicHeader />
      <div className="layoutPublic-container">
        <main className="layoutPublic-container-main ">
          {" "}
          <Outlet />{" "}
        </main>
      </div>
      <LayoutPublicMenuFooter />
    </div>
  );
};

export default LayoutPublic;
