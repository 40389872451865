import baseService from "./baseService";

export default class React extends baseService {
  constructor() {
    super();
    this.schemaName = "React/Sales";
  }

  getTemplates(entity) {
    return this.postObject(this.schemaName, "GetTemplates", entity);
  }

  getAccountingView(entity) {
    return this.postObject(this.schemaName, "GetAccountingView", entity);
  }

  updateAccounting(entity) {
    return this.postObject(this.schemaName, "UpdateAccounting", entity);
  }

  updatePlan(entity) {
    return this.postObject(this.schemaName, "UpdatePlan", entity);
  }

  getPlanList(entity) {
    return this.postObject(this.schemaName, "GetPlanList", entity);
  }

  getPlan(entity) {
    return this.postObject(this.schemaName, "GetPlan", entity);
  }

  getPlanPriceList(entity) {
    return this.postObject(this.schemaName, "GetPlanPriceList", entity);
  }

  getPlanPrice(entity) {
    return this.postObject(this.schemaName, "GetPlanPrice", entity);
  }

  updatePlanPrice(entity) {
    return this.postObject(this.schemaName, "UpdatePlanPrice", entity);
  }

  deletePlan(entity) {
    return this.postObject(this.schemaName, "DeletePlan", entity);
  }

  getPlanTextList(entity) {
    return this.postObject(this.schemaName, "GetPlanTextList", entity);
  }

  getPlanText(entity) {
    return this.postObject(this.schemaName, "GetPlanText", entity);
  }

  updatePlanText(entity) {
    return this.postObject(this.schemaName, "UpdatePlanText", entity);
  }

  getSelectPlanList(entity) {
    return this.postObject(this.schemaName, "GetSelectPlanList", entity);
  }

  getPlanDetail(entity) {
    return this.postObject(this.schemaName, "GetPlanDetail", entity);
  }

  updateCompanyPlan(entity) {
    return this.postObject(this.schemaName, "UpdateCompanyPlan", entity);
  }

  getOrderList(entity) {
    return this.postObject(this.schemaName, "GetOrderList", entity);
  }

  getOrderListExport(entity) {
    return this.postObject(this.schemaName, "GetOrderListExport", entity);
  }

  getCompanyPlanList(entity) {
    return this.postObject(this.schemaName, "GetCompanyPlanList", entity);
  }

  getCompanyPlanListExport(entity) {
    return this.postObject(this.schemaName, "GetCompanyPlanListExport", entity);
  }

  getPlanPricePreview(entity) {
    return this.postObject(this.schemaName, "GetPlanPricePreview", entity);
  }

  checkSalesUser(entity) {
    return this.postObject(this.schemaName, "CheckSalesUser", entity);
  }

  getAccrualList(entity) {
    return this.postObject(this.schemaName, "GetAccrualList", entity);
  }

  getAccrualListExport(entity) {
    return this.postObject(this.schemaName, "GetAccrualListExport", entity);
  }

  createInvoice(entity) {
    return this.postObject(this.schemaName, "CreateInvoice", entity);
  }

  getInvoiceList(entity) {
    return this.postObject(this.schemaName, "GetInvoiceList", entity);
  }

  getInvoiceListExport(entity) {
    return this.postObject(this.schemaName, "GetInvoiceListExport", entity);
  }

  updateInvoice(entity) {
    return this.postObject(this.schemaName, "UpdateInvoice", entity);
  }

  getCompanyPlanLimitList(entity) {
    return this.postObject(this.schemaName, "GetCompanyPlanLimitList", entity);
  }
}
