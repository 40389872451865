// genel sayfa içerinde map leme yapılacak veriler için
export const initialListState = {
  loading: true,
  hasErrors: false,
  hasMessage: null,
  items: [],
};

// genel sayfa içerinde map leme yapılacak veriler için
export const initialObjectState = {
  loading: true,
  hasErrors: false,
  hasMessage: null,
  item: null,
};

// selectbox
export const initialSelectState = {
  hasErrors: false,
  hasMessage: null,
  selectList: [],
};
